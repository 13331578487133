<template>
    <div class="page-templates">
        <!-- LIST OVERVIEW -->
        <div v-if="vm.view === 'overview'" key="overview">
            <page-header headline="Skabeloner">
                <div class="p-input-icon-left mx-2" style="width:100%;flex:1 1 auto;">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" style="width:100%" placeholder="Søg efter skabelon navn..." />
                </div>
                <Button label="Opret ny skabelon" icon="pi pi-plus" class="p-button-success" @click="fn.showCreateSidebar" />
            </page-header>
            <div class="content-wrapper">
                <div class="main-content" v-if="!vm.fetchingData">
                    <DataTable v-if="vm.documentTemplates && vm.documentTemplates.length"
                               ref="dt" stripedRows
                               :value="vm.documentTemplates"
                               dataKey="id"
                               sortField="updated" :sortOrder="-1"
                               :paginator="false" :rows="10" :filters="filters"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                               currentPageReportTemplate="Viser {first} til {last} af {totalRecords} dokumenter" responsiveLayout="scroll">
                        <!--<Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>-->
                        <Column field="name" header="Navn" :sortable="true" style="min-width: 12rem">
                            <template #body="slotProps">
                                <div class="flex">
                                    <i v-if="slotProps.data.type === 1" class="pi pi-dollar mr-2" style="font-size: 24px; color: rgba(72, 73, 161, 0.7) "></i>
                                    <i v-else-if="slotProps.data.type === 2" class="pi pi-file-pdf mr-2" style="font-size: 24px; color: rgba(72, 73, 161, 0.7) "></i>
                                    <i v-else class="pi pi-file mr-2" style="font-size: 24px; color: rgba(72, 73, 161, 0.7) "></i>
                                    <router-link class="p-link clickable" :to="{name:'AdminTemplates', params: {id: slotProps.data.id}}">
                                        <strong>{{slotProps.data.name}}</strong>
                                    </router-link>
                                </div>
                            </template>
                        </Column>
                        <Column field="updated" header="Opdateret" :sortable="true" style="width:230px">
                            <template #body="slotProps">
                                {{$filters.formatDate(slotProps.data.updated)}}
                            </template>
                        </Column>
                        <Column field="state" header="Status" :sortable="true" style="width:125px" class="column-state">
                            <template #body="slotProps">
                                <Tag v-if="!slotProps.data.isActive" value="Deaktiveret"></Tag>
                                <Tag v-else-if="slotProps.data.state === 1 || slotProps.data.state === 'PUBLISHED'" severity="success" value="Udgivet"></Tag>
                                <Tag v-else severity="info" value="Kladde"></Tag>
                            </template>
                        </Column>
                        <Column :exportable="false" class="text-center" style="width:80px">
                            <template #body="slotProps">
                                <Button type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text py-0 h-2rem w-2rem" aria-haspopup="true" :aria-controls="'overlay_menu_' + slotProps.data.id" @click="fn.toggleDotsMenu($event, slotProps.data)" />
                                <Menu :id="'overlay_menu_' + slotProps.data.id" class="kebab-slide-out-menu" :model="documentExtraActions" :popup="false" :class="{'block' : vm.visibleMenuId === slotProps.data.id }" />
                                <div v-if="vm.visibleMenuId === slotProps.data.id" class="fixed bottom-0 left-0 right-0 top-0" @click="vm.visibleMenuId = null"></div>
                            </template>
                        </Column>
                    </DataTable>
                    <Message severity="info" :closable="false" v-else>
                        Du har endnu ikke oprettet nogen skabeloner. Klik på "Opret ny skabelon" for at komme i gang
                    </Message>
                </div>
            </div>
            <Sidebar v-model:visible="vm.slideInCreate" :baseZIndex="1000" position="right" class="p-sidebar-md">
                <div class="flex flex-column h-full">
                    <h3>Opret ny skabelon</h3>
                    <div class="box mt-2">
                        <span class="p-float-label">
                            <InputText id="inputname" type="text" class="p-100 p-inputtext-lg" v-model="vm.currentTemplate.name" />
                            <label for="inputname">Indtast navn på skabelonen *</label>
                        </span>
                        <div class="mb-5"><small>Navnet vil blive brugt som reference i systemet og som filnavn, når vi sender en kopi af dokumentet til modtagerne.</small></div>
                        <div>
                            <div class="field-radiobutton mt-3">
                                <RadioButton inputId="dt-normal" name="document-template-type" value="0" v-model="vm.documentTemplateType" />
                                <label for="dt-normal"><strong>Almindelig skabelon</strong> <span class="secondary-text">(Du bygger selv dit dokument)</span></label>
                            </div>
                            <div class="field-radiobutton">
                                <RadioButton inputId="dt-files-upload" name="document-template-type" value="2" v-model="vm.documentTemplateType" />
                                <label for="dt-files-upload"><strong>PDF skabelon</strong> <span class="secondary-text">(Upload pdf til underskrivelse)</span></label>
                            </div>
                            <div class="field-radiobutton mb-5" v-if="store.config.enableStudentGrantModule">
                                <RadioButton inputId="dt-student-grant" name="document-template-type" value="1" v-model="vm.documentTemplateType" />
                                <label for="dt-student-grant"><strong>Statsstøtteskema</strong> <span class="secondary-text">(Ansøgning om statslig elevstøtte)</span></label>
                            </div>
                        </div>
                    </div>
                    <div class="mt-auto align-self-end sticky bottom-spacing">
                        <Button label="Opret" class="p-button-success p-button-lg" @click="fn.createDocumentTemplate()" :disabled="!vm.currentTemplate.name" />
                    </div>
                </div>

            </Sidebar>
        </div>
        <!-- EDIT -->
        <div v-if="vm.view === 'details'" key="details">
            <page-header :headline="vm.currentTemplate.state === 0 ? 'Opdater skabelon' : 'Skabelon detaljer'" :enableBackButton="true" @backAction="fn.goToOverview()">
                <div style="flex:0 0 auto;">
                    <Button v-if="vm.currentTemplate.state === 0" @click="fn.confirmDeleteTemplate(vm.currentTemplate)" class="p-button-danger" icon="pi pi-trash" label="Slet skabelon" />
                    <Button v-if="vm.currentTemplate.state !== 0" @click="fn.goToStats(vm.currentTemplate.id)" class="p-button-success" icon="pi pi-chart-bar" label="Statistik" />
                    <Button @click="fn.previewTemplate(vm.currentTemplate.id)" class="ml-2" icon="pi pi-eye" label="Forhåndsvisning" />
                </div>
            </page-header>
            <div class="content-wrapper">
                <div class="main-content">
                    <div class="box mb-3 relative">
                        <span class="p-float-label">
                            <InputText id="inputname" type="text" class="p-100 p-inputtext-lg" v-model="vm.currentTemplate.name" :disabled="vm.currentTemplate.state !== 0" />
                            <label for="inputname">Dokumentnavn</label>
                        </span>
                        <i class="absolute pi pi-question-circle" style="right: 30px; top: 35px; font-size: 20px;" v-tooltip.top="'Navnet vil blive brugt som reference i systemet, og som filnavn når vi sender en kopi af dokumentet til forældre/elev'"></i>
                    </div>

                    <TabView class="tabview-custom" ref="tabview4">
                        <TabPanel>
                            <template #header>
                                <i class="pi pi-palette"></i>
                                <span class="ml-2">1) Designer</span>
                            </template>
                            <template-builder v-model:grid-data="vm.currentTemplate.gridItems" :state="vm.currentTemplate.state" :type="vm.currentTemplate.type"></template-builder>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <i class="pi pi-envelope"></i>
                                <span class="ml-2">2) Afsendelse</span>
                            </template>
                            <Message v-if="vm.currentTemplate.state === 1" severity="info" class="mt-0" :closable="false">
                                Når en skabelon er publiceret, kan den ikke opdateres mere. Afsendelsesbeskederne kan dog ændres, når man sender dokumentet i bekræft afsendelse/påmindelse-boksen.
                            </Message>
                            <div class="grid">
                                <div class="col-12">
                                    <div class="box p-4">
                                        <h4>Sprog</h4>
                                        <div class="mb-3"><small>Vælg det sprog, hvori systembeskeder i e-mails og på knapper vises.</small></div>
                                        <div class="field-radiobutton">
                                            <RadioButton inputId="danish" name="language" :value="0" v-model.number="vm.currentTemplate.language" :disabled="vm.currentTemplate.state !== 0" />
                                            <label for="danish">Dansk</label>
                                        </div>
                                        <div class="field-radiobutton">
                                            <RadioButton inputId="english" name="language" :value="1" v-model.number="vm.currentTemplate.language" :disabled="vm.currentTemplate.state !== 0" />
                                            <label for="english">Engelsk</label>
                                        </div>
                                        <div class="field-radiobutton">
                                            <RadioButton inputId="danish_english" name="language" :value="2" v-model.number="vm.currentTemplate.language" :disabled="vm.currentTemplate.state !== 0" />
                                            <label for="danish_english">Dansk og Engelsk</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="box p-4">
                                        <h4>Besked ved første afsendelse</h4>
                                        <div class="mb-2"><small>Flettefelter: indsæt <strong>{elevnavn}</strong>, <strong>{elevefternavn}</strong>, <strong>{elevadresse}</strong>, <strong>{elevnummer}</strong> og/eller <strong>{klassetrin}</strong> og systemet vil automatisk indsætte elev navn, elev efternavn, elev adresse, elevnummer og/eller elev klassetrin.</small></div>
                                        <editor :init="editorInit"
                                                plugins="link lists quickbars"
                                                toolbar="bold underline strikethrough removeformat | link"
                                                v-model="vm.currentTemplate.emailText"
                                                :disabled="vm.currentTemplate.state !== 0" />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="box p-4">
                                        <h4 class="mb-3">Påmindelse</h4>
                                        <span class="p-float-label mb-3">
                                            <InputNumber id="inputname" class="p-100 p-inputtext-lg" v-model="vm.currentTemplate.autoRemindAfter" :useGrouping="false" :disabled="vm.currentTemplate.state !== 0" />
                                            <label for="inputname">Påmind modtager efter x dage</label>
                                        </span>
                                        <h5>Besked ved påmindelse</h5>
                                        <editor :init="editorInit"
                                                plugins="link lists quickbars"
                                                toolbar="bold underline strikethrough removeformat | link"
                                                v-model="vm.currentTemplate.reminderEmailText"
                                                :disabled="vm.currentTemplate.state !== 0" />
                                    </div>
                                </div>

                            </div>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <i class="pi pi-sliders-h"></i>
                                <span class="ml-2">3) Afslutning</span>
                            </template>
                            <Message v-if="vm.currentTemplate.state === 1" severity="info" class="mt-0" :closable="false">
                                Når en skabelon er publiceret, kan den ikke opdateres mere.
                            </Message>
                            <div class="grid">

                                <div class="col-12">
                                    <div class="box p-4">
                                        <h4 class="pb-2">Handling efter afslutning af dokument</h4>
                                        <div class="field-radiobutton">
                                            <RadioButton inputId="send-document-copy" name="post-publish-task" :value="0" v-model.number="vm.currentTemplate.postPublishTask" :disabled="vm.currentTemplate.state !== 0" />
                                            <label for="send-document-copy">Send kopi af færdig dokument til modtager</label>
                                        </div>
                                        <div class="field-radiobutton">
                                            <RadioButton inputId="send-confirmation" name="post-publish-task" :value="1" v-model.number="vm.currentTemplate.postPublishTask" :disabled="vm.currentTemplate.state !== 0" />
                                            <label for="send-confirmation">Send bekræftelse til modtager</label>
                                        </div>
                                        <div class="field-radiobutton">
                                            <RadioButton inputId="do-nothing" name="post-publish-task" :value="2" v-model.number="vm.currentTemplate.postPublishTask" :disabled="vm.currentTemplate.state !== 0" />
                                            <label for="do-nothing">Ingen handling</label>
                                        </div>
                                        <div v-if="vm.currentTemplate.postPublishTask === 1">
                                            <h4 class="mb-0 mt-4">Besked ved bekræftelse</h4>
                                            <div class="mb-2"><small>Flettefelter: indsæt <strong>{elevnavn}</strong>, <strong>{elevefternavn}</strong>, <strong>{elevadresse}</strong>, <strong>{elevnummer}</strong> og/eller <strong>{klassetrin}</strong> og systemet vil automatisk indsætte elev navn, elev efternavn, elev adresse, elevnummer og/eller elev klassetrin.</small></div>
                                            <editor :init="editorInit"
                                                    plugins="link lists quickbars"
                                                    toolbar="bold underline strikethrough removeformat | link"
                                                    v-model="vm.currentTemplate.confirmationText"
                                                    :disabled="vm.currentTemplate.state !== 0" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </TabPanel>
                    </TabView>
                    <div class="fixed-right-bottom p-text-right" v-if="vm.currentTemplate.state === 0">
                        <Button @click="fn.publishDocumentTemplate" class="p-button-link" label="Gem og udgiv skabelon" />
                        <Button @click="fn.updateDocumentTemplate" class="p-button-success" icon="pi pi-check" label="Gem kladde" />
                    </div>

                </div>
            </div>
            <ConfirmDialog group="confirmPublish" :style="{'max-width':'750px',width: '100%', margin:'0.5rem'}">
                <div style="min-height:700px">
                    <Message severity="warn" :closable="false" class="mt-0">Efter udgivelse er det kun muligt at ændre i besked til afsendelse samt påmindelses interval.</Message>
                    <div class="align-items-center flex mt-4">
                        <i class="pi pi-envelope" style="font-size: 26px;width: 3rem;"></i>
                        <h5>Besked ved første afsendelse</h5>
                    </div>
                    <div class="ml-6" style="height:200px">
                        <editor :init="editorInitDialog"
                                plugins="link lists quickbars"
                                toolbar="bold underline strikethrough removeformat | link"
                                v-model="vm.currentTemplate.emailText" />
                    </div>
                    <div class="align-items-center flex mt-4">
                        <i class="pi pi-bell" style="font-size: 26px;width: 3rem;"></i>
                        <h5>Påmindelse</h5>
                    </div>
                    <div class="p-float-label my-3 ml-6">
                        <InputNumber id="inputname" class="p-100 p-inputtext-lg" v-model="vm.currentTemplate.autoRemindAfter" :useGrouping="false" />
                        <label for="inputname">Påmind modtager efter x dage</label>
                    </div>
                    <div class="ml-6 mb-2" style="font-size: 13px;opacity: 0.6;">Besked ved påmindelse</div>
                    <div class="ml-6" style="height:200px">
                        <editor :init="editorInitDialog"
                                plugins="link lists quickbars"
                                toolbar="bold underline strikethrough removeformat | link"
                                v-model="vm.currentTemplate.reminderEmailText" />
                    </div>
                </div>
            </ConfirmDialog>
        </div>
        <!-- DIALOGS -->
        <ConfirmDialog group="confirmDeleteTemplate" :style="{'max-width': '600px', width: '100%'}">
            <div>Er du sikker på at du vil slette skabelonen <b>{{vm.currentTemplate.name}}</b>?</div>
            <Message v-if="vm.isDraft" severity="info" :closable="false">Skabelonen flyttes til papirkurven, hvor den kan gendannes inden for papirkurvens opbevaringsperiode.</Message>
            <div v-else>
                <Message severity="info" :closable="false">Skabelonen flyttes til papirkurven, hvor den kan gendannes inden for papirkurvens opbevaringsperiode.</Message>
                <Message severity="warn" :closable="false">Alle afsendte dokumenter, der bruger skabelonen vil også blive slettet og efterfølgende arkiveret jf slettereglerne under "indstillinger"</Message>
            </div>
        </ConfirmDialog>
        <!-- LOADER -->
        <div v-if="vm.busy" class="overlay overlay-submitting">
            <ProgressSpinner />
        </div>
    </div>
</template>

<script setup>
    import { inject, ref, reactive } from 'vue';
    import { FilterMatchMode } from 'primevue/api';
    import Editor from '@tinymce/tinymce-vue';
    import { handlePastePreprocess } from './../../composables/paste-preprocess';
    import { useToast } from 'primevue/usetoast';
    import { useConfirm } from "primevue/useconfirm";
    import api from './../../composables/api';
    import gqlBuilder from './../../composables/gql-builder';
    import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'


    // Vars
    const store = inject('store');
    const apiUrl = inject('apiUrl');
    const toast = useToast();
    const confirm = useConfirm();
    const route = useRoute();
    const router = useRouter();
    const filters = ref({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const dt = ref();
    const vm = reactive({
        documentTemplateType: '0',
        documentTemplates: null,
        currentTemplate: {},
        slideInCreate: false,
        view: 'overview',
        loadingItems: new Array(4),
        fetchingData: true,
        busy: false,
        visibleMenuId: null
    });
    const editorInitDialog = {
        height: 200,
        license_key: 'gpl',
        paste_preprocess: handlePastePreprocess,
        menubar: false,
        default_link_target: '_blank',
        quickbars_image_toolbar: false,
        quickbars_insert_toolbar: false
    };
    const editorInit = {
        height: 300,
        license_key: 'gpl',
        paste_preprocess: handlePastePreprocess,
        menubar: false,
        default_link_target: '_blank',
        quickbars_image_toolbar: false,
        quickbars_insert_toolbar: false
    };
    const documentExtraActions = ref([
        {
            label: 'Rediger',
            icon: 'pi pi-pencil',
            visible: () => {
                return vm.currentTemplate.state === 0;
            },
            command: () => {
                fn.editDocumentTemplate(vm.currentTemplate.id);
            }
        },
        {
            label: 'Statistik',
            icon: 'pi pi-chart-bar',
            visible: () => {
                return vm.currentTemplate.state !== 0;
            },
            command: () => {
                fn.goToStats(vm.currentTemplate.id);
            }
        },
        {
            label: 'Kopier',
            icon: 'pi pi-clone',
            command: () => {
                fn.cloneAndSaveDocumentTemplate(vm.currentTemplate);
                vm.visibleMenuId = null;
            }
        },
        {
            label: 'Forhåndsvisning',
            icon: 'pi pi-eye',
            visible: () => {
                return vm.currentTemplate.state === 1;
            },
            command: () => {
                fn.previewTemplate(vm.currentTemplate.id);
            }
        },
        {
            label: 'Deaktivér',
            icon: 'pi pi-history',
            visible: () => {
                return vm.currentTemplate.isActive && vm.currentTemplate.state !== 0;
            },
            command: () => {
                fn.setActiveState(false);
                vm.visibleMenuId = null;
            }
        },
        {
            label: 'Genaktivér',
            icon: 'pi pi-undo',
            visible: () => {
                return !vm.currentTemplate.isActive;
            },
            command: () => {
                fn.setActiveState(true);
                vm.visibleMenuId = null;
            }
        },
        {
            label: 'Slet',
            icon: 'pi pi-trash',
            command: () => {
                fn.confirmDeleteTemplate(vm.currentTemplate);
                vm.visibleMenuId = null;
            }
        }
    ]);


    // Methods
    const fn = {};
    fn.confirmDeleteTemplate = (template) => {
        vm.currentTemplate = template;
        vm.isDraft = template.state === 0;

        confirm.require({
            group: 'confirmDeleteTemplate',
            header: 'Bekræft slet skabelon',
            acceptLabel: 'Ja, slet',
            acceptClass: 'p-button-success p-button-lg',
            rejectLabel: 'Nej, luk',
            rejectClass: 'p-button-link p-button-lg',
            accept: () => {
                fn.deleteDocumentTemplate();
            }
        });

    };
    fn.showCreateSidebar = () => {
        vm.currentTemplate = {};
        vm.slideInCreate = true;
    };
    fn.setActiveState = (isActive) => {
        api.patch(apiUrl + 'documenttemplate/' + vm.currentTemplate.id, { isActive: isActive }).then((res) => {
            if (res.status === 200) {
                vm.currentTemplate.isActive = isActive;
                toast.add({ severity: 'success', summary: 'Gennemført', detail: isActive ? 'Skabelonen er genaktiveret' : 'Skabelonen er deaktiveret', life: 3000 });
            } else {
                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: res.status });
            }

        }).catch((error) => {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
        });
    }
    fn.previewTemplate = (templateId) => {
        if (templateId) {
            // Is document template published?
            // No need to save before preview
            if (vm.currentTemplate.state === 1) {
                window.open('/document/preview/' + templateId, '_blank');
            } else {
                fn.updateDocumentTemplate(() => {
                    window.open('/document/preview/' + templateId, '_blank');
                });
            }

        }
    };
    fn.deleteDocumentTemplate = () => {
        api.del(apiUrl + 'documenttemplate/' + vm.currentTemplate.id).then(() => {
            vm.documentTemplates = vm.documentTemplates.filter(val => val.id !== vm.currentTemplate.id);
            vm.view = 'overview';
            toast.add({ severity: 'success', summary: 'Skabelon slettet', life: 3000 });
            vm.deleteText = '';
            vm.currentTemplate = {};
        }).catch((error) => {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
        });
    };
    fn.publishDocumentTemplate = () => {
        confirm.require({
            group: 'confirmPublish',
            header: 'Bekræft udgiv dokument samt indstillinger',
            acceptLabel: 'Ja, udgiv',
            acceptClass: 'p-button-success p-button-lg',
            rejectLabel: 'Nej, luk',
            rejectClass: 'p-button-link p-button-lg',
            accept: () => {
                vm.currentTemplate.state = 1;

                fn.updateDocumentTemplate(() => {
                    vm.currentTemplate = {};
                    fn.goToOverview();
                    toast.add({ severity: 'success', summary: 'Dokumentet er udgivet og klar til afsendelse', life: 5000 });
                });
            }
        });
    };
    fn.createDocumentTemplate = () => {
        var gridItems = [];

        if (vm.documentTemplateType === '1') {
            gridItems = [
                {
                    id: null,
                    type: 7,
                    sortOrder: 1,
                    incomeYear: 0,
                    schoolYear: '',
                    courseLengthInWeeks: 0,
                    courseStartDate: new Date().toJSON(),
                    courseEndDate: new Date().toJSON()
                },
                {
                    id: null,
                    type: 2,
                    sortOrder: 2,
                    signatureType: '1',
                    whichRecipientsMustSign: '0',
                    senderSignAction: '0',
                    senderMustSign: false,
                }
            ];
        } else if (vm.documentTemplateType === '2') {
            gridItems = [
                {
                    id: null,
                    type: 9,
                    sortOrder: 1,
                    files: []
                },
                {
                    id: null,
                    type: 2,
                    sortOrder: 2,
                    signatureType: '1',
                    whichRecipientsMustSign: '0',
                    senderSignAction: '0',
                    senderMustSign: false,
                    confirmMasterData: false
                }
            ];
        }
        vm.currentTemplate.gridItems = gridItems;
        vm.currentTemplate.type = parseInt(vm.documentTemplateType);

        api.postJson(apiUrl + 'documenttemplate', vm.currentTemplate).then((response) => {
            if (response) {
                vm.documentTemplates.push(response);
                vm.currentTemplate = response;
                fn.editDocumentTemplate(vm.currentTemplate.id);
            } else {
                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: 'Prøv igen om lidt' });
            }
            vm.slideInCreate = false;

        }).catch((error) => {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
        });
    };
    fn.editDocumentTemplate = (documentTemplateId) => {
        api.query(gqlBuilder.documentTemplates.getById(store.auth.customerId, documentTemplateId)).then((response) => {
            if (api.hasData(response)) {
                var data = sanitizeDocumentTemplateBeforeLoad(api.getData(response));
                vm.currentTemplate = data;
                vm.view = 'details';

            } else {
                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.errors[0].message });
            }
        });
    };
    fn.cloneAndSaveDocumentTemplate = (documentTemplate) => {
        api.query(gqlBuilder.documentTemplates.getById(store.auth.customerId, documentTemplate.id)).then((response) => {
            if (api.hasData(response)) {
                vm.currentTemplate = api.getData(response);
                vm.currentTemplate.id = null;
                vm.currentTemplate.name = vm.currentTemplate.name + ' (KOPI)';
                vm.currentTemplate.state = 0;

                // If PDF template, remove file ref
                if (vm.currentTemplate.type === 2) {
                    vm.currentTemplate.gridItems.forEach(item => {
                        if (item.type === 9) {
                            item.files = [];
                        }
                    });
                }

                const documentTemplateSanitized = prepareDocumentTemplateForClone(sanitizeDocumentTemplateBeforeSave(vm.currentTemplate));
                api.mutation(gqlBuilder.documentTemplates.update(store.auth.customerId, documentTemplateSanitized)).then((response) => {
                    if (api.hasData(response)) {
                        vm.currentTemplate = {};
                        vm.documentTemplates.push(api.getData(response));
                        toast.add({ severity: 'info', summary: 'Kopieret', life: 3000 });

                    } else {
                        toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.errors[0].message });
                    }
                });

            } else {
                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.errors[0].message });
            }
        });
    }
    fn.updateDocumentTemplate = (callback) => {
        const documentTemplateSanitized = sanitizeDocumentTemplateBeforeSave(vm.currentTemplate);
        vm.busy = true;

        api.mutation(gqlBuilder.documentTemplates.update(store.auth.customerId, documentTemplateSanitized)).then((response) => {
            vm.busy = false;
            if (api.hasData(response)) {
                // UPDATE DOCUMENT TEMPLATE IN LIST VIEW
                if (vm.documentTemplates && vm.documentTemplates.length) {
                    //TODO: vm.currentTemplate.id is without dashes (graphql)
                    const documentTemplateIndex = vm.documentTemplates.findIndex((obj => obj.id.replaceAll('-', '') === vm.currentTemplate.id));
                    if (documentTemplateIndex >= 0) {
                        vm.documentTemplates[documentTemplateIndex] = api.getData(response);
                    }
                }

                if (typeof callback === 'function') {
                    callback();
                } else {
                    vm.currentTemplate = {};
                    fn.goToOverview();

                    toast.add({ severity: 'success', summary: 'Skabelonen er gemt', life: 3000 });
                }

            } else {
                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.errors[0].message });
            }
        });
    };
    fn.goToOverview = () => {
        vm.view = 'overview';
        router.push({
            name: 'AdminTemplates'
        });
       
    };
    fn.goToStats = (id) => {
        if (id) {
            router.push({ name: 'AdminTemplateStats', params: { id: id } });
        }
    };
    fn.toggleDotsMenu = (event, data) => {
        vm.currentTemplate = data;
        vm.visibleMenuId = vm.currentTemplate.id === vm.visibleMenuId ? null : vm.currentTemplate.id;
    };

    // Init
    var init = (goToOverview) => {
        if (!goToOverview && route.params && route.params.id) {
            fn.editDocumentTemplate(route.params.id);
        } else {
            vm.view = 'overview';

            if (!vm.documentTemplates || !vm.documentTemplates.length) {
                vm.fetchingData = true;

                api.get(apiUrl + 'documenttemplate').then((response) => {
                    if (response) {
                        vm.documentTemplates = response;
                        vm.fetchingData = false;
                    } else {
                        toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: 'response empty' });
                    }
                }).catch((error) => {
                    toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
                });
            }
        }
    }

    onBeforeRouteUpdate((to, from, next) => {
        if (to.params && to.params.id) {
            fn.editDocumentTemplate(to.params.id);
        } else {
            init(true);
        }

        next();
    });


    ///// INIT
    init();


    function sanitizeDocumentTemplateBeforeLoad(template) {
        for (var i = 0, l = template.gridItems.length; i < l; i++) {
            var gridItem = template.gridItems[i];
            // TODO: fix endpoint to return UTC time
            if (gridItem.type === 7) {
                gridItem.courseStartDate = new Date(gridItem.courseStartDate);
                gridItem.courseEndDate = new Date(gridItem.courseEndDate);
            }
        }
        return template;
    }

    function sanitizeDocumentTemplateBeforeSave() {
        // Clone currentTemplate so user doesn't see we're preparing for save
        const current = JSON.parse(JSON.stringify(vm.currentTemplate));

        for (var i = 0, l = current.gridItems.length; i < l; i++) {
            // Consents
            // Removing everything except id
            var consents = current.gridItems[i].consents;
            if (consents && consents.length > 0) {
                var consentIds = [];
                for (var k in consents) {
                    consentIds.push({ id: consents[k].id, sortOrder: parseInt(k) });
                }
                current.gridItems[i].consents = consentIds;
            }

            //Student grant
            if (current.gridItems[i].type === 7) {
                // TODO: fix datetime output. This shouldn't be needed as long as we save it as utc time
                var startDate = new Date(current.gridItems[i].courseStartDate);
                current.gridItems[i].courseStartDate = new Date(startDate.setHours(4, 0, 0, 0)).toJSON();
                var endDate = new Date(current.gridItems[i].courseEndDate);
                current.gridItems[i].courseEndDate = new Date(endDate.setHours(4, 0, 0, 0)).toJSON();

            }

            // Questions
            // Removing empty answers
            //if (current.gridItems[i].questions) {
            //    var answers = current.gridItems[i].questions[0].as;
            //    for (var j in answers) {
            //        if (answers[j].text === '') {
            //            answers.splice(j, 1);
            //        }
            //    }
            //}
        }
        return current;
    }

    function prepareDocumentTemplateForClone() {
        // Clone currentTemplate so user doesn't see we're preparing for clone
        const current = JSON.parse(JSON.stringify(vm.currentTemplate));

        // Removing all id's
        for (var i = 0, l = current.gridItems.length; i < l; i++) {
            var gridItem = current.gridItems[i];
            gridItem.id = null;

            // Questions and answers
            if (gridItem.questions) {
                for (var j = 0, qL = gridItem.questions.length; j < qL; j++) {
                    var question = gridItem.questions[j];
                    question.id = null;

                    // Answers
                    for (var k = 0, aL = question.as.length; k < aL; k++) {
                        var answer = question.as[k];
                        answer.id = null;
                    }
                }
            }

            // Inputfields
            if (gridItem.inputFields) {
                for (var m = 0, iL = gridItem.inputFields.length; m < iL; m++) {
                    gridItem.inputFields[m].id = null;
                }
            }

        }
        return current;
    }



</script>

<style scoped>
    .fixed-right-bottom {
        background-color: white;
        border-radius: 10px;
    }
</style>
<style>
    .p-tabview .p-tabview-panels {
        background: rgba(255, 255, 255,0.8);
        /*        padding-left: 0;
        padding-right: 0;*/
    }

        .p-tabview .p-tabview-panels .box {
            border: 1px solid #dee2e6;
        }
</style>